import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get, isNull, isUndefined, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core';

import { contentfulContentTypes, magentoContentTypes } from '../../../../constants/index';
import { isSSR } from '../../../../utils/windowUtils';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    '@media print': {
      display: 'none',
    },
  },
});

function ReactAndShare({ classes, className, pageData }) {
  const contentType = get(pageData, 'internal.type');

  useEffect(() => {
    if (isSSR()) {
      return () => {};
    }

    let apiKey;

    if (isEqual(contentType, contentfulContentTypes.ArticlePage)) {
      apiKey = process.env.GATSBY_REACT_AND_SHARE_ARTICLE_PAGE_KEY;
    } else if (isEqual(contentType, magentoContentTypes.Product)) {
      apiKey = process.env.GATSBY_REACT_AND_SHARE_PRODUCT_KEY;
    }

    window.rnsData = { apiKey };

    const script = document.createElement('script');
    script.src = 'https://cdn.reactandshare.com/plugin/rns.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [contentType]);

  if (isNull(contentType) || isUndefined(contentType)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className="rns" />
    </div>
  );
}

ReactAndShare.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

ReactAndShare.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(ReactAndShare);
