import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import ViinimaaReactAndShare from './viinimaa';

function ReactAndShare(props) {
  if (isEqual(siteId, siteIds.Viinimaa)) {
    return <ViinimaaReactAndShare {...props} />;
  }
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return null;
  }

  return null;
}

export default ReactAndShare;
