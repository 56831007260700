import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { withStyles, Typography } from '@material-ui/core';

const styles = () => ({
  root: {
    flex: '1',
    margin: '20px 0',
  },
  content: {
    textAlign: 'right',
  },
  publishingDate: {
    fontWeight: 'normal',
    fontSize: '1rem',
  },
});

function PublishingDate({ classes, className, data }) {
  const publishingDate = useMemo(() => {
    return DateTime.fromISO(data).toFormat('dd.MM.yyyy');
  }, [data]);

  if (isEmpty(data)) {
    return null;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <Typography variant="overline" align="center" className={classes.publishingDate}>
          {publishingDate}
        </Typography>
      </div>
    </div>
  );
}

PublishingDate.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.string,
};

PublishingDate.defaultProps = {
  classes: {},
  className: null,
  data: null,
};

export default withStyles(styles)(PublishingDate);
