/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import PropTypes from 'prop-types';
import get from 'lodash/get';
import tail from 'lodash/tail';
import initial from 'lodash/initial';
import compact from 'lodash/compact';

export default function TriggerBeeBanner({ pageData }) {
  const id = get(pageData, 'contentful_id');
  const fullPath = get(pageData, 'fullPath');

  const classes = initial(tail(compact(fullPath.split('/'))));

  return <div id={`campaign-container-${id}`} className={`campaign-container-article ${classes.join(' ')}`} />;
}

TriggerBeeBanner.propTypes = {
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TriggerBeeBanner.defaultProps = {
  pageData: null,
};
