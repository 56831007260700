import React from 'react';

import { graphql } from 'gatsby';

import CommonLandingPageTemplate from './ArticlePageTemplate';
import AnoraProLandingPageTemplate from './anoraPro';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function ArticlePageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProLandingPageTemplate {...props} />;
  }
  return <CommonLandingPageTemplate {...props} />;
}

export const pageQuery = graphql`
  query ArticlePageTemplate($pageId: String!) {
    contentfulArticlePage(id: { eq: $pageId }) {
      id
      contentful_id
      title
      menuTitle
      shortDescription
      fullPath
      publishingDate
      languageCode
      publishingDate
      internal {
        type
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      searchPage {
        ...SearchPageFragment
      }
      breadcrumbs {
        ...BreadcrumbFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      content {
        ...ContentFragment
      }
      categories {
        ...CategoryFragment
      }
      relatedPages {
        ...RelatedPageFragment
      }
      brands {
        ...BrandPageFragment
      }
      mainTheme {
        ...themeFragment
      }
    }
  }
`;

export default ArticlePageTemplate;
