import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

import get from 'lodash/get';
import includes from 'lodash/includes';

import ProtectedRoute from '../../../components/authentication/ProtectedRoute/ProtectedRoute';
import { anoraProUnprotectedPages } from '../../../constants';
import ArticlePageTemplate from '../ArticlePageTemplate';

function AnoraProArticlePageTemplate({ ...props }) {
  const fullPath = get(props, 'data.contentfulArticlePage.fullPath');
  const unprotectedPages = Object.values(anoraProUnprotectedPages);
  const { isAuthenticated } = useAuth0();

  if (includes(unprotectedPages, fullPath) && !isAuthenticated) {
    return <ArticlePageTemplate {...props} />;
  }

  return (
    <ProtectedRoute>
      <ArticlePageTemplate {...props} />;
    </ProtectedRoute>
  );
}

AnoraProArticlePageTemplate.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
};

AnoraProArticlePageTemplate.defaultProps = {
  data: null,
  classes: {},
};

export default AnoraProArticlePageTemplate;
