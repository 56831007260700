import React from 'react';

import { isEqual } from 'lodash';

import { siteIds } from '../../../constants';
import { siteId } from '../../../sites';

import FolkofolkTriggerBeeBanner from './folkofolk';

function TriggerBeeBanner(props) {
  if (isEqual(siteId, siteIds.Folkofolk)) {
    return <FolkofolkTriggerBeeBanner {...props} />;
  }

  return null;
}

export default TriggerBeeBanner;
