import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { get } from 'lodash';

import { Container, withStyles } from '@material-ui/core';

import { contentPageMaxWidth } from '../../../../constants';
import Categories from '../../Categories';
import PublishingDate from '../../PublishingDate';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  publishingDate: {
    marginLeft: 'auto',
  },
});

function CategoriesAndPublishingDate({ classes, className, pageData }) {
  const categories = get(pageData, 'categories');
  const publishingDate = get(pageData, 'publishingDate');
  return (
    <Container maxWidth={contentPageMaxWidth} className={clsx(classes.root, className)}>
      <Categories categoriesData={categories} />
      <PublishingDate data={publishingDate} className={classes.publishingDate} />
    </Container>
  );
}

CategoriesAndPublishingDate.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  pageData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

CategoriesAndPublishingDate.defaultProps = {
  classes: {},
  className: null,
  pageData: null,
};

export default withStyles(styles)(CategoriesAndPublishingDate);
